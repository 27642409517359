a{
  color:#222222;
  display: block;
}
a:hover{
  color:var(--green-logo);
  text-decoration:none;
}
a:focus{
  text-decoration: none;
  color: inherit;
  outline: none;
  outline-offset: initial;
  box-shadow: initial;
  border: 0;
}
a, 
button, 
input {
    transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
}
.btn-primary{
  background-color:var(--green-logo);
  padding: 0 20px;
  border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  height: 46px;
  line-height: 46px;
  border: none;
  display: inline-block;
}
.btn-primary:hover{
  background:var(--green-logo);
  background-color:var(--green-logo);
}

/*------------------------------------------------------------------
3.7. Login / #login
-------------------------------------------------------------------*/

.form-login{
  max-width: 430px;
  margin: 2% auto;
}
.form-login h2{
  font-size: 18px;
  margin-bottom: 20px;
}
.form-login input{
  border: 1px solid #ededed;
  width: 100%;
  height: 46px;
}
.form-login .error{
  color:  #F8326C;
} 

.form-login .btn-primary{
  float: left;
  background:var(--green-logo);
  height: 38px;
  line-height: 38px;
  color: #fff;
}

.form-login .inline{
  margin-top: 10px;
}
.form-login .inline p{
  float: right;
  display: inline-block;
  line-height: 46px;
}
.form-login .inline p a{
  display: inline-block;
  text-decoration: underline;
  color: #222222;
}
